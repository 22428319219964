import React, { useState, useRef } from "react"
import AnimateHeight from 'react-animate-height';
import { CSSTransition } from "react-transition-group"

import font from "../../fonts/fonts.module.scss"
import styled from "styled-components"

import arrowRight from "../../images/svg/setaBigD.svg"
import arrow from "../../images/svg/setaB.svg"
import arrowFilled from "../../images/svg/setaBigDFill.svg"
import checkSuccess from "../../images/svg/check-success.svg"

import CustomAxios from "../../custom/axios"

const Form = ({ data, title }) => {
  const [catOpen, setCatOpen] = useState(false);
  const [termosCheck, setTermos] = useState(false);
  const [response, setResponse] = useState("");
  const [uploadedfile, setUploadedfile] = useState(null);

  const name = useRef(null);
  const categoria = useRef(null);
  const email = useRef(null);
  const phone = useRef(null);
  const fname = useRef(null);
  const nif = useRef(null);
  const morada = useRef(null);
  const comprova = useRef(null);
  const termos = useRef(null);

  function doSubmit(e) {
    e.preventDefault()
    checkValues();
    if (checker.length < 1) {
      var formData = new FormData()
      formData.append("name", name.current.value)
      formData.append("categoria", categoria.current.value)
      formData.append("email", email.current.value)
      formData.append("phone", phone.current.value)
      formData.append("fname", fname.current.value)
      formData.append("nif", nif.current.value)
      formData.append("morada", morada.current.value)
      formData.append("comprova", uploadedfile)
      formData.append("formacao", title)
      CustomAxios("https://ipmd.pt/formacao.php", formData, "ENVIADO").then(res => {
        setResponse(res)
      });
    }
    else {
      setResponse("Faltam os seguintes campos: " + checker + ".");
    }
  }

  var checker = [];

  function checkValues() {
    checker = [];
    let checkerTemp = [];

    name.current.style.borderBottom = "2px solid #717171";
    categoria.current.style.borderBottom = "2px solid #717171";
    email.current.style.borderBottom = "2px solid #717171";
    phone.current.style.borderBottom = "2px solid #717171";
    fname.current.style.borderBottom = "2px solid #717171";
    nif.current.style.borderBottom = "2px solid #717171";
    morada.current.style.borderBottom = "2px solid #717171";
    comprova.current.style.borderBottom = "2px solid #717171";
    termos.current.style.borderBottom = "none";

    if (!name.current.value) {
      checkerTemp.push("nome");
      name.current.style.borderBottom = "2px solid red";
    }
    if (!categoria.current.value) {
      checkerTemp.push("email");
      categoria.current.style.borderBottom = "2px solid red";
    }
    if (!email.current.value) {
      checkerTemp.push("email");
      email.current.style.borderBottom = "2px solid red";
    }
    if (!phone.current.value) {
      checkerTemp.push("telefone");
      phone.current.style.borderBottom = "2px solid red";
    }
    if (!fname.current.value) {
      checkerTemp.push("fname");
      fname.current.style.borderBottom = "2px solid red";
    }
    if (!nif.current.value) {
      checkerTemp.push("nif");
      nif.current.style.borderBottom = "2px solid red";
    }
    if (!morada.current.value) {
      checkerTemp.push("morada");
      morada.current.style.borderBottom = "2px solid red";
    }
    if (!comprova.current.value || uploadedfile.size > 5000000) {
      checkerTemp.push("comprovativo");
      comprova.current.style.borderBottom = "2px solid red";
    }
    if (!termosCheck) {
      termos.current.style.borderBottom = "2px solid red";
      if (checkerTemp === []) {
        checkerTemp.push("aceitar os nossos termos e políticas");
      }
      else {
        checkerTemp.push("e aceitar os nossos termos e políticas");
      }
    }
    checker = checkerTemp;
  }

  return (
    <StyledContainer>
      <CSSTransition in={response !== "ENVIADO"} timeout={350} classNames={"switch"} unmountOnExit>
        <>
          <div>
            <h3 className={font.nexaRegular + " form-title"} onClick={() => { console.log(uploadedfile.size < 5000000) }}>{data.title}</h3>
            <hr />
          </div>

          <div className="form-contacto">
            <p className={font.nexaRegular + " form-title"}>{data.contacto.title}</p>
            <div className="form-flex">
              <div className="form-input">
                <p className={font.nexaRegular + " form-lbl"}>{data.contacto.name}</p>
                <input type="text" ref={name} className={font.nexaRegular} />
              </div>
              <div className="form-input">
                <button onClick={() => { setCatOpen(!catOpen) }}>
                  <p className={font.nexaRegular + " form-lbl"}>{data.contacto.categoria.title}</p>
                  <img src={arrow} alt="seta" style={{ transform: catOpen ? "rotate(-90deg)" : "rotate(0deg)" }} />
                </button>
                <AnimateHeight duration={600} height={catOpen ? "auto" : 0} className="cat-options">
                  {data.contacto.categoria.options.map((data, i) => (
                    <button onClick={() => { categoria.current.value = data; setCatOpen(false) }}>
                      <p className={font.nexaRegular + " small"} key={"option" + i}>{data}</p>
                    </button>
                  ))}
                </AnimateHeight>
                <input type="text" ref={categoria} className={font.nexaRegular} readOnly />
              </div>
            </div>
            <div className="form-flex">
              <div className="form-input">
                <p className={font.nexaRegular + " form-lbl"}>{data.contacto.email}</p>
                <input type="text" ref={email} className={font.nexaRegular} />
              </div>
              <div className="form-input">
                <p className={font.nexaRegular + " form-lbl"}>{data.contacto.phone}</p>
                <input type="text" ref={phone} className={font.nexaRegular} />
              </div>
            </div>
          </div>

          <div className="form-contacto">
            <p className={font.nexaRegular + " form-title"}>{data.fatura.title}</p>
            <div className="form-flex">
              <div className="form-input">
                <p className={font.nexaRegular + " form-lbl"}>{data.fatura.name}</p>
                <input type="text" ref={fname} className={font.nexaRegular} />
              </div>
              <div className="form-input">
                <p className={font.nexaRegular + " form-lbl"}>{data.fatura.nif}</p>
                <input type="text" ref={nif} className={font.nexaRegular} />
              </div>
            </div>
            <div className="form-flex">
              <div className="form-input">
                <p className={font.nexaRegular + " form-lbl"}>{data.fatura.morada}</p>
                <input type="text" ref={morada} className={font.nexaRegular} />
              </div>
              <div className="form-input">
                <p className={font.nexaRegular + " form-lbl"}>{data.fatura.comprovativo}</p>
                <input type="file" accept="image/*,.pdf" ref={comprova} className={font.nexaRegular + " file"} onChange={(e) => setUploadedfile(e.target.files[0])} />
                <p className={font.nexaRegular + " form-lbl small"}>Tamanho máximo: 5MB</p>
              </div>
            </div>
          </div>

          <button onClick={() => { setTermos(!termosCheck) }} className="termos-btn" ref={termos}>
            <img src={termosCheck ? arrowFilled : arrowRight} alt="seta" />
            <p className={font.nexaRegular}>{data.termos}</p>
          </button>

          <button onClick={doSubmit} className="submit-btn">
            <p className={font.nexaRegular}>{data.btn}</p>
            <img src={arrowRight} alt="seta" />
          </button>
        </>
      </CSSTransition>
      <CSSTransition in={response === "ENVIADO"} timeout={350} classNames={"switch"} unmountOnExit>
        <div className="response">
          <div className="response-content">
            <img src={checkSuccess} alt="Sucesso" className="sucesso-check" />
            <h4 className={font.nexaRegular + " res-title"}>Obrigado</h4>
            <h4 className={font.nexaRegular + " res-subtitle"}>Recebemos o seu email e iremos entrar em contacto assim que possível.</h4>
          </div>
        </div>
      </CSSTransition>
    </StyledContainer>
  )
}

export default Form

const StyledContainer = styled.div`
  position: relative;
  width: 85%;
  height: 100%;
  background-color: #EFEFEF;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15%;
  gap: 3vw;
  padding: 3vw 5vw;
  box-shadow: 40px 40px 40px 13px rgb(0 0 0 / 15%);
  color: #717171;
  @media only screen and (max-width: 768px){
    margin-right: 0;
    width: 100%;
    border-radius: 0;
    padding: 12vw 8vw;
  }
  .form-title{
    color: #17D4E3;
    margin-bottom: 0.5em;
  }
  hr{
    border: 1px solid #17D4E3;
    width: 5em;
    @media only screen and (max-width: 768px){
      margin-bottom: 2em;
    }
  }
  .form-contacto{
    width: 100%;
    .form-title{
      margin-bottom: 1.5em;
    }
  }
  .form-flex{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2vw;
    margin: 2vw 0;
    position: relative;
    @media only screen and (max-width: 768px){
      grid-template-columns: 1fr;
      .form-input{
        margin: 1em 0;
      }
    }
  }
  .form-input{
    input{
      width: 100%;
      background: none;
      border: none;
      border-bottom: 2px solid #717171;
      outline: none;
      margin-top: 1em;
    }
    button{
      display: flex;
      img{
        height: 1em;
        transition: all 600ms;
        margin-left: 1em;
      }
    }
  }
  .cat-options{
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    z-index: 5;
    div{
      background-color: #ffffff;
      padding: 1vw 2vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      button:hover{
        color: #17D4E3;
      }
      p{
        line-height: 3em;
        white-space: nowrap;
      }
    }
  }
  .file{
    position: relative;
    top: -2px;
  }
  .termos-btn, .submit-btn{
    display: flex;
    align-items: center;
  }
  .termos-btn{
    align-self: flex-start;
    margin-top: -2vw;
    img{height: 1em; margin-right: 1em;}
  }
  .submit-btn{
    align-self: flex-end;
    img{height: 1em; margin-left: 1em;}
    @media only screen and (max-width: 768px){
      margin-top: 2em;
    }
  }

  .switch-enter{
    opacity: 0 !important;
    position: absolute;
  }
  .switch-enter-active{
    opacity: 1 !important;
    transition: all 350ms ease-out;
  }
  .switch-exit{
    opacity: 1 !important;
  }
  .switch-exit-active{
    opacity: 0 !important;
    transition: all 350ms ease-out;
  }
  .response{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    .response-content{
      position: relative;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .sucesso-check{
      width: 5vw;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -100%);
    }
    .res-title{
      color: #17D4E3;
      text-transform: uppercase;
      letter-spacing: 0.38em;
      text-align: center;
      margin-top: 1em;
    }
    .res-subtitle{
      color: #717171;
      text-transform: uppercase;
      margin: 1em 0;
      text-align: center;
    }
  }
  .form-lbl{
    white-space: nowrap;
  }
`