import React, { useState, useRef } from "react"
import useEventListener from "../custom/use-event-listener"
import { CSSTransition } from "react-transition-group"

import styled from "styled-components"
import { Button } from "./buttons"
import font from "../fonts/fonts.module.scss"

import checkbox from "../images/svg/setaBigD.svg"
import checked from "../images/svg/setaD.svg"
import enviar from "../images/svg/enviar.svg"
import closeHover from "../images/svg/X-Azul.svg"
import close from "../images/svg/X-Cinzento.svg"

import CustomAxios from "../custom/axios"

const Consulta = ({ setModal }) => {
  const [response, setResponse] = useState("");
  const [termosCheck, setTermosCheck] = useState(false);
  const [nameFocus, setNameFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [phoneFocus, setPhoneFocus] = useState(false);
  const [dateFocus, setDateFocus] = useState(false);
  const [detailsFocus, setDetailsFocus] = useState(false);
  const [sending, setSending] = useState(false);
  const [closeBtn, setCloseBtn] = useState(false);

  const name = useRef(null);
  const email = useRef(null);
  const phone = useRef(null);
  const date = useRef(null);
  const details = useRef(null);
  const termos = useRef(null);

  var checker = [];

  function doSubmit(e) {
    e.preventDefault()
    checkValues();
    if (checker.length < 1) {
      var formData = new FormData()
      formData.append("name", name.current.value)
      formData.append("email", email.current.value)
      formData.append("phone", phone.current.value)
      formData.append("date", date.current.value)
      formData.append("details", details.current.value)
      formData.append("consulta", true)

      CustomAxios("https://ipmd.pt/form-contact.php", formData, "ENVIADO").then((res) => {
        setResponse(res)
      });
    }
    else {
      setResponse("Faltam os seguintes campos: " + checker + ".");
    }
  }

  function checkValues() {
    checker = [];
    let checkerTemp = [];

    name.current.style.borderBottom = "1px solid #707070";
    email.current.style.borderBottom = "1px solid #707070";
    phone.current.style.borderBottom = "1px solid #707070";
    date.current.style.borderBottom = "1px solid #707070";
    termos.current.style.borderBottom = "1px solid #707070";

    if (!name.current.value) {
      checkerTemp.push("nome");
      name.current.style.borderBottom = "1px solid red";
    }
    if (!email.current.value) {
      checkerTemp.push("email");
      email.current.style.borderBottom = "1px solid red";
    }
    if (!phone.current.value) {
      checkerTemp.push("telefone");
      phone.current.style.borderBottom = "1px solid red";
    }
    if (!date.current.value) {
      checkerTemp.push("data");
      date.current.style.borderBottom = "1px solid red";
    }
    if (!termosCheck) {
      termos.current.style.borderBottom = "1px solid red";
      if (checkerTemp === []) {
        checkerTemp.push("aceitar os nossos termos e políticas");
      }
      else {
        checkerTemp.push("e aceitar os nossos termos e políticas");
      }
    }
    checker = checkerTemp;
  }

  useEventListener("mousedown", (e) => sair(e));
  function sair(e) {
    !document.querySelector('.clickbox').contains(e.target) && setModal(false);
  }
  return (
    <StyledConsulta>
      <CSSTransition in={response !== "ENVIADO"} timeout={350} classNames={"switch"} unmountOnExit>
        <div className="clickbox">

          <Button regular noHover className="close" onClick={() => { setModal(false) }} onMouseEnter={() => { setCloseBtn(true) }} onMouseLeave={() => { setCloseBtn(false) }}>
            <CSSTransition in={(closeBtn)} timeout={350} classNames={"switch"} unmountOnExit>
              <img src={closeHover} alt="Picker" />
            </CSSTransition>
            <CSSTransition in={!closeBtn} timeout={350} classNames={"switch"} unmountOnExit>
              <img src={close} alt="Picker" />
            </CSSTransition>
          </Button>

          <h3 className={font.nexaHeavy + " title"}>MARQUE JÁ A SUA CONSULTA</h3>
          <hr className="line" />
          <p className={font.nexaXBold + " subtitle"}>Faça aqui o seu pedido de marcação e aguarde pelo nosso contacto."</p>

          <form method="post" action="#">
            <div className="formFlex">
              <div className={(nameFocus || (name.current && name.current.value)) ? "labelgroup  labelgroupFocus is65" : "labelgroup is65"}>
                <p className={font.nexaRegular + " label"}>Nome*</p>
                <input type="text" name="name" ref={name} className={font.nexaRegular} onFocus={() => { setNameFocus(true) }} onBlur={() => { setNameFocus(false) }} />
              </div>
              <div className={(phoneFocus || (phone.current && phone.current.value)) ? "labelgroup  labelgroupFocus is35" : "labelgroup is35"}>
                <p className={font.nexaRegular + " label"}>Telemóvel*</p>
                <input type="tel" name="phone" ref={phone} className={font.nexaRegular} onFocus={() => { setPhoneFocus(true) }} onBlur={() => { setPhoneFocus(false) }} />
              </div>
            </div>

            <div className="formFlex">
              <div className={(emailFocus || (email.current && email.current.value)) ? "labelgroup  labelgroupFocus is65" : "labelgroup is65"}>
                <p className={font.nexaRegular + " label"}>Email*</p>
                <input type="email" name="email" ref={email} className={font.nexaRegular} onFocus={() => { setEmailFocus(true) }} onBlur={() => { setEmailFocus(false) }} />
              </div>
              <div className={(dateFocus || (date.current && date.current.value)) ? "labelgroup  labelgroupFocus is35" : "labelgroup is35"}>
                <p className={font.nexaRegular + " label"}>Data*</p>
                <input type="date" name="date" ref={date} className={font.nexaRegular} onFocus={() => { setDateFocus(true) }} onBlur={() => { setDateFocus(false) }} />
                <CSSTransition in={(dateFocus || (date.current && date.current.value))} timeout={350} classNames={"switch"} unmountOnExit>
                  <img src={checked} alt="Picker" className="pickerImg" />
                </CSSTransition>
                <CSSTransition in={!(dateFocus || (date.current && date.current.value))} timeout={350} classNames={"switch"} unmountOnExit>
                  <img src={checkbox} alt="Picker" className="pickerImg" />
                </CSSTransition>
              </div>
            </div>

            <div className="formFlex">
              <div className={(detailsFocus || (details.current && details.current.value)) ? "labelgroup  labelgroupFocus details is100" : "labelgroup details is100"}>
                <p className={font.nexaRegular + " label"}>Observações</p>
                <textarea ref={details} className={font.nexaRegular} onFocus={() => { setDetailsFocus(true) }} onBlur={() => { setDetailsFocus(false) }} />
              </div>
            </div>

            <label className={"labelgroup labelTermos"}>
              <CSSTransition in={termosCheck === true} timeout={350} classNames={"switch"} unmountOnExit>
                <img src={checked} alt="Newsletter" className="checkmark" />
              </CSSTransition>
              <CSSTransition in={termosCheck === false} timeout={350} classNames={"switch"} unmountOnExit>
                <img src={checkbox} alt="Newsletter" className="checkmark" />
              </CSSTransition>
              <input type="checkbox" name="termos" className={font.nexaRegular} onClick={() => { setTermosCheck(!termosCheck) }} />
              <p className={font.nexaRegular + " label"} ref={termos}>Aceito os<a href="/politica_de_privacidade.pdf" target="_blank" rel="nofollow noreferrer">&nbsp;termos de política e privacidade*</a></p>
            </label>

            <Button regular noHover type="submit" className="btnSend" onClick={doSubmit} onMouseEnter={() => { setSending(true) }} onMouseLeave={() => { setSending(false) }}>
              <CSSTransition in={sending === true} timeout={350} classNames={"switch"} unmountOnExit>
                <img src={checked} alt="Enviar" />
              </CSSTransition>
              <CSSTransition in={sending === false} timeout={350} classNames={"switch"} unmountOnExit>
                <img src={checkbox} alt="Enviar" />
              </CSSTransition>
              <img src={enviar} alt="Enviar" />
            </Button>
          </form>

          <div className={font.nexaRegular + " response"}>
            <p>{response !== "ENVIADO" && response}</p>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition in={response === "ENVIADO"} timeout={350} classNames={"switch"} unmountOnExit>
        <div className="clickbox">

          <Button regular noHover className="close" onClick={() => { setModal(false) }} onMouseEnter={() => { setCloseBtn(true) }} onMouseLeave={() => { setCloseBtn(false) }}>
            <CSSTransition in={(closeBtn)} timeout={350} classNames={"switch"} unmountOnExit>
              <img src={closeHover} alt="Picker" />
            </CSSTransition>
            <CSSTransition in={!closeBtn} timeout={350} classNames={"switch"} unmountOnExit>
              <img src={close} alt="Picker" />
            </CSSTransition>
          </Button>

          <h3 className={font.nexaHeavy + " title"}>ENVIADO!</h3>
          <hr className="line" />
          <p className={font.nexaXBold + " subtitle"}>Obrigado pelo seu contacto, seremos o mais breve possível.</p>
        </div>
      </CSSTransition>
    </StyledConsulta>
  )
}

export default Consulta

const StyledConsulta = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(2px);
  pointer-events: all;
  .close{
    position: absolute;
    top: 40px;
    right: 40px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    img{
      left: 0;
      width: 100%;
      height: 100%;
    }
    @media only screen and (max-width: 768px){
      top: 4vw;
      right: 4vw;
      width: 4vw;
      height: 4vw;
    }
  }
  .clickbox{
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 80px;
    width: 50%;
    color: #717171;
    box-shadow: 21.5px 21.5px 27.5px 0 rgba(0, 0, 0, 0.16);
    border-radius: 50px;
    @media only screen and (max-width: 768px){
      width: 90%;
      padding: 5vw;
      border-radius: 25px;
    }
    form{
      display: flex;
      flex-direction: column;
    }
    .title{
      color: #17d4e3;
      text-align: center;
      letter-spacing: 0.1em;
      margin-bottom: 30px;
      @media only screen and (max-width: 768px){
        margin-bottom: 0;
      }
    }
    .line{
      width: 50px;
      border: none;
      margin: 1em auto;
      border-top: #17d4e3 1px solid;
    }
    .subtitle{
      margin-bottom: 30px;
      text-align: center;
      color: #717171;
      width: 50%;
      margin: 0 auto 20px;
      letter-spacing: 0.02em;
    }
    .formFlex{
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: 768px){
        display: block;
      }
    }
    .is65{
      width: 65%;
    }
    .is35{
      width: 35%;
    }
    .is100{
      width: 100%;
    }
    .is65, .is35{
      @media only screen and (max-width: 768px){
        width: 100%;
      }
    }
    .labelgroup{
      margin: 20px 10px;
      position: relative;
      @media only screen and (max-width: 768px){
        margin: 20px 0 0;
      }
      .label{
        position: absolute;
        top: -5px;
        pointer-events: none;
        letter-spacing: normal;
        transition: all 0.3s;
      }
      input, textarea, input[type=date]{
        width: 100%;
        height: 100%;
        font-size: 14px;
        border: none;
        border-bottom: 1px solid #707070;
        outline: none;
        color: #717171;
        padding: 1px 2px;
        transition: border 0.3s ease-out;
      }
      input[type=date]{
        color: transparent;
        ::-webkit-calendar-picker-indicator{
          opacity: 0;
          cursor: pointer;
        }
      }
      .pickerImg{
        height: 0.7em;
        position: absolute;
        right: 0.1em;
        bottom: 0.5em;
        pointer-events: none;
        transform: rotate(90deg);
      }
      textarea{
        border: 1px solid #707070;
        height: 4em;
        resize: none;
      }
    }
    .labelgroupFocus{
      .label{
        font-size: 12px;
        transform:  translateY(-1.2em);
      }
      input[type=date]{
        color: #717171;
      }
    }
    .details{
      @media only screen and (max-width: 768px){
        margin: 40px 0 0;
      }
      .label{
        transform: unset;
        transform:  translateY(-1.2em);
        transition: border 0.3s ease-out;
      }
    }
    .button{
      font-size: 18px;
      letter-spacing: 0.1em;
    }
    .labelTermos{
      display: flex;
      align-items: center;
      margin: 0 10px;
      .label{position: relative; top: 0;letter-spacing: 0.1em;}
      p{
        letter-spacing: 0.1em;
        display: flex;
        a{
          display: contents;
          font-size: inherit;
          pointer-events: all;
        }
      }
    }
    input[type=checkbox] {
      width: 0;
      height: 0;
      display: none;
    }
    .checkmark{
      margin-right: 0.5em;
      width: 15px;
      height: 1em;
      cursor: pointer;
    }
    .btnSend{
      position: relative;
      align-self: flex-end;
      display: flex;
      margin: 1em 0;
      img{height: 0.9em; margin-right: 0.5em;}
    }
    .response{
      margin: 1em;
      font-size: 20px;
      text-align: center;
    }
  }

  .switch-enter{
    opacity: 0 !important;
    position: absolute;
  }
  .switch-enter-active{
    opacity: 1 !important;
    transition: all 350ms ease-out;
  }
  .switch-exit{
    opacity: 1 !important;
  }
  .switch-exit-active{
    opacity: 0 !important;
    transition: all 350ms ease-out;
  }
`